import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';

export function capitalizeWords(str) {
    log.trace('capitalizeWords: [str]', str);
    if (str !== undefined && str !== null && str !== '') {
        str = str.length > 60 ? str.slice(0, 60) + '...' : str;
        return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    }
    return ''
}

export function productSubtitle(external_id = null, title, gtin) {
    var subtitle = '';
    if (title) {
        subtitle = capitalizeWords(title);
        if (external_id) {
            subtitle = external_id + ' - ' + subtitle;
        }

        if (gtin) {
            subtitle = subtitle + ' (' + gtin + ')';
        }
    }
    return subtitle;
}

export function productSubtitle2(price, stock, stock_location, cost = null) {
    let info = [];

    if (price) {
        price = price.toFixed(2);
        info.push('Preço: R$ ' + price.replace('.', ','));
    }
    if (cost) {
        cost = cost.toFixed(2);
        info.push('Custo: R$ ' + cost.replace('.', ','));
    }
    if (stock !== null && stock !== '' && stock !== undefined) {
        info.push('Estoque: ' + stock);
    }
    if (stock_location !== null && stock_location !== '' && stock_location !== undefined) {
        info.push('Local: ' + stock_location);
    }

    return info.join(' | ');
}

export function dateFormatted(date = new Date()) {
    const formattedDate = date.toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
        hour12: false,
        minute: 'numeric',
        hour: 'numeric',
        day: 'numeric',
        month: 'numeric',
        year: '2-digit', // Include year as 2 digits
    })
    return formattedDate.replace(',', '');
}

export function dateFormattedDatagrid(date = new Date()) {
    return (
        <div style={{ whiteSpace: 'pre-wrap' }}>
            {new Date(date).toLocaleString(
                'pt-BR',
                { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: 'America/Sao_Paulo' }
            ).replace(',', '')}
        </div>
    );
}

export function removeUnchangedFields(fields, newFields) {
    let fieldsChanged = {};

    const unitsFields = ['height', 'width', 'length', 'volume', 'weight']
    const unitsMeasurementFields = ['fk_dimension_unit', 'fk_volume_unit', 'fk_weight_unit']
    let unitsChanged = false;

    for (const field in fields) {
        if (unitsFields.includes(field) && fields[field] !== newFields[field]) {
            unitsChanged = true;
            break;
        }
    }

    for (const field in fields) {
        for (const key in fields[field]) {
            if (fields[field][key] !== newFields[field][key] || (unitsChanged && unitsMeasurementFields.includes(field))) {
                fieldsChanged[field] = newFields[field];
                break;
            }
        }
    }
    return fieldsChanged;
}

export function orderTitleFormatted(order, maxLength = 90, titleProp = 'title', includeQuantity = false) {
    let result = order.items[0][titleProp]
    let comp = "";

    for (let i = 0; i < order.items.length; i++) {
        if (i < order.items.length - 1) {
            result += `, ${order.items[i + 1].title}`;
        }
    }

    if (includeQuantity) {
        if (order.items.length === 1) {
            result = "x" + order.items[0].quantity + " " + result;
        }
    }

    if (order.items.length > 1) {
        if (result.length > maxLength) {
            const count = order.items.length - 1;
            if (count === 1)
                comp += `... e mais ${count} item`;
            else
                comp += `... e mais ${count} itens`;
            result = result.slice(0, maxLength - comp.length);
            result = result.replace(/,([^,]*)$/, '');
            result += comp;
        }
    } else {
        if (result.length > maxLength)
            result = result.slice(0, maxLength - 3) + "...";
    }
    return result;
}

export async function toFormData(images) {
    let imagesFiles = new FormData();

    await images.forEach((image) => {
        if (image instanceof File) {
            imagesFiles.append(uuidv4(), image);
        }
    });

    return imagesFiles;
}
