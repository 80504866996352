import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper'
import Sidebar from '../components/Sidebar';
import ModalAlert from '../components/ModalAlert';
import CenteredLoading from '../components/CenteredLoading';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ApiService from '../utils/ApiService';
import Summary from '../components/Summary';
import { useAuth } from '../contexts/AuthContext';

export default function Review() {
    const [products, setProducts] = useState(null);
    const [productIndex, setProductIndex] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [scrollPos, setScrollPos] = useState(0);
    const { displaySetting } = useAuth();

    useEffect(() => {
        const onScroll = e => {
            setScrollPos(e.target.scrollTop);
        };
        window.addEventListener("scroll", onScroll, true);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollPos, window]);

    useEffect(() => {
        ApiService.get('/products_to_review').then((response) => {
            setProducts(response);
            setProductIndex(0);
        }).catch((error) => {
            setAlertMessage(error.message);
        });
    }, []);

    const acceptData = () => {
        ApiService.post('/accept_review', { "id": products[productIndex] }).then((_) => {
            setProductIndex(productIndex + 1);
            document.getElementById('product-navigation').scrollIntoView();
        }).catch((error) => {
            setAlertMessage(error.message);
        });
    }

    useEffect(() => {
        window.document.title = "Revisar | " + displaySetting('site_name');
    }, []);

    return (
        <Sidebar title={"Revisar Produtos"}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }} >
                {(products !== null) ?
                    (products.length > 0) ?
                        <React.Fragment>
                            <Grid container sx={{ marginBottom: '10px', marginTop: '10px' }} id="product-navigation">
                                <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                    <Button variant="contained" onClick={() => setProductIndex(productIndex - 1)} disabled={productIndex === 0}>Anterior</Button>
                                </ Grid>
                                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                    <h3>SKU: {products[productIndex]} - Produto {productIndex + 1} de {products.length}</h3>
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: 'right' }}>
                                    <Button variant="contained" onClick={() => setProductIndex(productIndex + 1)} disabled={productIndex === products.length - 1}>Próximo</Button>
                                </Grid>
                            </Grid>
                            <Summary productId={products[productIndex]} returnFunction={acceptData} disableBackButton={true} />
                        </React.Fragment>
                        : <h3>Não há produtos para revisão</h3>
                    : <CenteredLoading />}
            </Paper>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            { /* Floating button to go the bottom when current position is not the bottom */}
            {(document.body.scrollHeight - scrollPos) > 0 && window.innerWidth > 1280 &&
                <Fab onClick={() => document.getElementById('bottom').scrollIntoView()}
                    sx={{ position: 'fixed', bottom: '20px', right: '50px', zIndex: 1000, width: '40px', height: '40px' }}
                    variant="contained" color="primary"><ArrowDownwardIcon /></Fab>
            }
            <div style={{ float:"left", clear: "both" }} id="bottom"></div>
        </Sidebar>
    );
}
