import React from 'react';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import {
    Card, CardHeader, CardContent, CardActions, Button, Typography, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Collapse, IconButton, Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import * as Constants from '../utils/constants';
import { orderTitleFormatted } from '../utils/utils';

import { saveAs } from 'file-saver';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontFamily: 'Work Sans',
    },
    section: {
        margin: 5,
        padding: 2,
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4',
        borderBottomStyle: 'solid',
    },
    header: {
        fontSize: 24,
        marginBottom: 15,
        textAlign: 'center',
        color: '#333',
    },
    companyDetails: {
        fontSize: 12,
        marginBottom: 10,
    },
    tableContainer: {
        marginBottom: 10,
    },
    tableHeader: {
        flexDirection: 'row',
        borderBottom: '1px solid #ccc',
        textAlign: 'left',
        padding: 5,
        fontSize: 12,
        marginBottom: 5,
    },
    tableRow: {
        flexDirection: 'row',
        textAlign: 'left',
        fontSize: 5,
    },
    tableCell: {
        padding: 2,
        flexGrow: 1,
        fontSize: 12,
    },
    tableSubCell: {
        padding: 2,
        flexGrow: 1,
        fontSize: 10,
    },
    tableSubCellAlignRight: {
        padding: 2,
        flexGrow: 1,
        fontSize: 10,
        alignItems: 'flex-end',
    },
    totalContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 10,
    },
    totalLabel: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    totalValue: {
        fontSize: 14,
        marginLeft: 5,
    },
});

const InvoicePDF = ({ client, ourCompany, orders, invoice, commissionRate }) => {
    const titleMaxLen = 100;

    Font.register({
        family: 'Work Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/@expo-google-fonts/work-sans@0.2.3/WorkSans_400Regular.ttf'},
            { src: 'https://cdn.jsdelivr.net/npm/@expo-google-fonts/work-sans@0.2.3/WorkSans_700Bold.ttf', fontWeight: 600 }
        ]
    });

    function cutTitle(title, maxLen) {
        if (title.length > maxLen) {
            return title.substring(0, maxLen) + '...';
        }
        return title;
    }

    return (
        <Document>
            <Page style={styles.page}>
                <Text style={styles.header}>Fatura de {invoice.start_date} até {invoice.end_date}</Text>

                <View style={styles.section}>
                    <Text style={styles.companyDetails}>Cliente: {client.company_name}</Text>
                    <Text style={styles.companyDetails}>CNPJ: {client.cnpj}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.companyDetails}>Emitido por: {ourCompany.company}</Text>
                    <Text style={styles.companyDetails}>Email: {ourCompany.email}</Text>
                    <Text style={styles.companyDetails}>CNPJ: {ourCompany.cnpj}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.companyDetails}>Faturamento do Período: R$ {invoice.total_sales.toFixed(2)}</Text>
                    <Text style={styles.companyDetails}>Saldo líquido das vendas: R$ {invoice.total_leftover.toFixed(2)}</Text>
                    <Text style={styles.companyDetails}>Comissão do Período: R$ {invoice.total_commission.toFixed(2)} ({commissionRate * 100}%)</Text>
                </View>

                <View style={styles.tableContainer}>
                    <View style={styles.tableHeader}>
                        <Text style={styles.tableCell}>Hora </Text>
                        <Text style={styles.tableCell}>Pedido</Text>
                        <Text style={styles.tableCell}>Valor da Venda (R$)</Text>
                        <Text style={styles.tableCell}>Base Cálculo (R$)</Text>
                        <Text style={styles.tableCell}>Comissão (R$)</Text>
                    </View>
                    {orders.map((day, dayIndex) => (
                        <View key={dayIndex}>
                            <Text style={{ fontWeight: 'bold', fontSize: 12, marginBottom: 5, marginTop: 10 }} >{day.date}</Text>
                            {day.orders.map((order, orderIndex) => (
                                <React.Fragment key={orderIndex}>
                                    <View style={{ backgroundColor: '#f6f6f6', marginBottom: 5 }}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCell}>{order.datetime}</Text>
                                            <Text style={styles.tableCell} href={Constants.ORDER_EDIT_URL + order.order_id}>{order.order_id}</Text>
                                            <Text style={styles.tableCell}>R$ {order.sale_price.toFixed(2)}</Text>
                                            <Text style={styles.tableCell}>R$ {order.leftover.toFixed(2)}</Text>
                                            <Text style={styles.tableCell}>R$ {order.commission.toFixed(2)}</Text>
                                        </View>
                                        {order.items.map((product, prodIndex) => (
                                            <View key={prodIndex} style={{ ...styles.tableRow }}>
                                                <Text style={styles.tableSubCell}>x{product.quantity} {cutTitle(product.title, titleMaxLen)}</Text>
                                            </View>
                                        ))}
                                    </View>
                                </React.Fragment>
                            ))}
                        </View>
                    ))}
                </View>

                <View style={styles.totalContainer}>
                    <Text style={styles.totalLabel}>Total: R$ {invoice.total_commission.toFixed(2)}</Text>
                </View>
            </Page>
        </Document>
    );
};

const InvoiceGenerator = ({ client, ourCompany, orders, invoice, commissionRate }) => {
    const [open, setOpen] = React.useState(orders.map(day => day.orders.map(() => false)));

    const handleClick = (dayIndex, orderIndex) => {
        setOpen((prevOpen) => {
            const newOpen = [...prevOpen];
            newOpen[dayIndex][orderIndex] = !newOpen[dayIndex][orderIndex];
            return newOpen;
        });
    };

    return (
        <Card sx={{ maxWidth: '1800px', margin: 'auto', mt: 5 }}>
            <CardHeader
                title={`Fatura de ${invoice.start_date} até ${invoice.end_date}`}
                subheader={`${client.company_name} - CNPJ: ${client.cnpj}`}
                sx={{
                    backgroundColor: (theme) => theme.palette.grey[200],
                }}
            />
            <CardContent>
                <Box sx={{ mb: 3 }}>
                    <Typography>Faturamento do Período: R$ {invoice.total_sales.toFixed(2)}</Typography>
                    <Typography>Saldo líquido das vendas: R$ {invoice.total_leftover.toFixed(2)}</Typography>
                    <Typography>Comissão do Período: R$ {invoice.total_commission.toFixed(2)} ({commissionRate * 100}%)</Typography>
                </Box>

                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Pedido</TableCell>
                                <TableCell>Hora</TableCell>
                                <TableCell>Valor da Venda (R$)</TableCell>
                                <TableCell>Comissão (R$)</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((day, dayIndex) => (
                                <React.Fragment key={dayIndex}>
                                    <TableRow>
                                        <TableCell colSpan={6}><strong>{day.date}</strong></TableCell>
                                    </TableRow>
                                    {day.orders.map((order, orderIndex) => (
                                        <React.Fragment key={orderIndex}>
                                            <TableRow>
                                                <TableCell>
                                                    <IconButton edge="start" aria-label="Venda" href={Constants.ORDER_EDIT_URL + order.order_id} target="_blank">
                                                        <ShoppingCartIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>{orderTitleFormatted(order, 90, 'title', true)}</TableCell>
                                                <TableCell>{order.datetime}</TableCell>
                                                <TableCell>R$ {order.sale_price.toFixed(2)}</TableCell>
                                                <TableCell>R$ {order.commission.toFixed(2)}</TableCell>
                                                <TableCell>
                                                    {order.items.length > 1 &&
                                                        <IconButton size="small" onClick={() => handleClick(dayIndex, orderIndex)}>
                                                            {open[dayIndex][orderIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={open[dayIndex][orderIndex]} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <Table size="small" aria-label="items">
                                                            <TableHead>
                                                                <TableRow sx={{ backgroundColor: '#f0f0f0', borderBottom: 'none'}}>
                                                                    <TableCell>Produto</TableCell>
                                                                    <TableCell>Preço (R$)</TableCell>
                                                                    <TableCell>Quantidade</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {order.items.map((product, prodIndex) => (
                                                                    <TableRow key={prodIndex} sx={{ border: 'none' }}>
                                                                        <TableCell>{product.title}</TableCell>
                                                                        <TableCell>R$ {product.price.toFixed(2)}</TableCell>
                                                                        <TableCell>{product.quantity}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="h6" align="right" sx={{ mt: 2 }}>
                    Total: R$ {invoice.total_commission.toFixed(2)}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <PDFDownloadLink
                    document={<InvoicePDF client={client} ourCompany={ourCompany} orders={orders} invoice={invoice} commissionRate={commissionRate} />}
                    fileName={`fatura_c2soft_${invoice.date.replace(/-/g, '_')}.pdf`}
                    style={{ textDecoration: 'none' }}
                >
                    {({ loading }) => (
                        <Button variant="contained" color="primary">
                            {loading ? 'Carregando documento...' : 'Baixar Fatura'}
                        </Button>
                    )}
                </PDFDownloadLink>
            </CardActions>
        </Card>
    );
};

export const generateAndDownloadPDF = async (invoiceData) => {
    const blob = await pdf(
        <InvoicePDF client={invoiceData.client} ourCompany={invoiceData.our_company} orders={invoiceData.orders}
            invoice={invoiceData.invoice} commissionRate={invoiceData.commission_rate} />
    ).toBlob();
    saveAs(blob, `fatura_c2soft_${invoiceData.date.replace(/-/g, '_').format('DD_MM_YYYY')}.pdf`);
};

export default InvoiceGenerator;
