import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Sidebar from '../components/Sidebar';
import ProductEditor from '../components/ProductEditor';
import ModalAlert from '../components/ModalAlert';
import CenteredLoading from '../components/CenteredLoading';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';

export default function HistoryPage() {
    const defSidebarTitle = 'Histórico';
    const [sidebarTitle, setSidebarTitle] = useState(defSidebarTitle);
    const [productsHistory, setProductsHistory] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const { displaySetting } = useAuth();

    const setErrorMsg = (msg) => {
        setAlertMessage(msg);
    };

    const refreshProducts = async () => {
        window.document.title = defSidebarTitle + " | " + displaySetting('site_name');
        ApiService.get('/history').then((response) => {
            setProductsHistory(response.history);
            setAlertMessage("");
        }).catch((error) => {
            setErrorMsg(error.message);
        });
    }

    useEffect(() => {
        window.document.title = "Histórico | " + displaySetting('site_name');
    }, []);

    useEffect(() => {
        refreshProducts();
    }, []);

    const extraColumns = [
        { field: 'timestamp', headerName: 'Data', minWidth: 90, flex: 12, sortable: true },
        { field: 'title', headerName: 'Título atual', flex: 50, sortable: true },
        { field: 'username', headerName: 'Usuário', minWidth: 90, flex: 8, sortable: true },
        { field: 'gtin', headerName: 'Código de barras', minWidth: 130, flex: 9, sortable: false }
    ];

    return (
        <Sidebar title={sidebarTitle}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }}>
                {(productsHistory !== null) ?
                    <ProductEditor products={productsHistory} refreshProducts={refreshProducts} setSidebarTitle={setSidebarTitle} parentTitle={defSidebarTitle}
                        updatedColumns={extraColumns} showGtinIcon={false} />
                    : <CenteredLoading />
                }
            </Paper>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar>
    );
}
