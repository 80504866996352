import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Sidebar from '../components/Sidebar';
import ModelAlert from '../components/ModalAlert';
import CenteredLoading from '../components/CenteredLoading';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Button, Typography, Stack } from '@mui/material';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';
import InvoiceGenerator from '../components/InvoiceGenerator';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export default function Report() {
    const [alertMessage, setAlertMessage] = useState('');
    const [commissionReport, setCommissionReport] = useState(undefined);
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs().endOf('month'));
    const [sidebarTitle] = useState('Relatório de Comissões');
    const { displaySetting } = useAuth();

    const status_report = [ // TODO: get from backend
        { name: 'status_completed', label: 'Finalizado', filter: ['paid'] },
        { name: 'status_unfinished', label: 'Cancelado', filter: ['cancelled'] },
        { name: 'status_new', label: 'Novo', filter: ['processing', 'pending'] },
    ]

    const [statusSelected, setStatusSelected] = useState(status_report[0].name);

    const setErrorMsg = (msg) => {
        setCommissionReport(null);
        setAlertMessage(msg);
    };

    const fetchCommissionReport = async () => {
        window.document.title = "Produtos | " + displaySetting('site_name');
        setCommissionReport(null);
        ApiService.get('/commission_report', {
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            status: statusSelected
        }).then((response) => {
            setCommissionReport(response.commission_report);
            setAlertMessage("");
        }).catch((error) => {
            setErrorMsg(error.message);
            setCommissionReport(undefined);
        });
    };

    useEffect(() => {
        window.document.title = "Relatório | " + displaySetting('site_name');
    }, []);

    return (
        <Sidebar title={sidebarTitle}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', textAlign: 'center' }}>
                <Box sx={{ padding: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ mb: 3 }}>
                            <DatePicker
                                label="Data de Início"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                views={['year', 'month', 'day']}
                                format="DD/MM/YYYY"
                            />
                            <DatePicker
                                label="Data de Fim"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                views={['year', 'month', 'day']}
                                format="DD/MM/YYYY"
                            />
                            <FormControl>
                                <InputLabel > {'Filtro por Status'} </InputLabel>
                                <Select label={'Filtro por Status'} value={statusSelected} onChange={(e) => setStatusSelected(e.target.value)}>
                                    {Object.entries(status_report).map(([, option], index) => (
                                        <MenuItem key={index} value={option.name} >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button variant="contained" onClick={fetchCommissionReport}>
                                Gerar Relatório
                            </Button>
                        </Stack>
                    </LocalizationProvider>
                </Box>
                {commissionReport === null ? (
                    <CenteredLoading />
                ) : commissionReport === undefined ? (
                    <Typography variant="body1" gutterBottom sx={{ mb: 3 }}>
                        Selecione um intervalo de datas e clique em <b>Gerar Relatório</b> para visualizar as comissões.
                    </Typography>
                ) : (
                    <InvoiceGenerator
                        client={commissionReport.client}
                        ourCompany={commissionReport.our_company}
                        orders={commissionReport.orders}
                        invoice={commissionReport.invoice}
                        commissionRate={commissionReport.commission_rate}
                    />
                )}
            </Paper>
            <ModelAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar>
    );
}
