// Component: ProductsDatagrid
// Description: This is the main component for the Products and SearchProducts pages

import React, { useEffect, useState } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EditIcon from '@mui/icons-material/Edit';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { useTheme } from '@mui/material/styles';
import { dateFormattedDatagrid } from '../utils/utils';
import { useAuth } from '../contexts/AuthContext';

const ProductsDatagrid = ({ products, extraColumns, showEditIcon = true, showExternalId = false, showGtinIcon = false, handleCellClick }) => {
    const theme = useTheme();
    const { displaySetting } = useAuth();

    const getColor = (issue) => {
        if (issue)
            return theme.palette.error.main;
        return theme.palette.disabled.main;
    };

    const createColumns = () => {
        let editDataColumn = showEditIcon ? [
            {
                field: 'editData',
                headerName: '',
                sortable: false,
                minWidth: 40,
                flex: 2,
                renderCell: (params) => (
                    <EditIcon
                        color={params.row.needs_data ? 'primary' : 'disabled'}
                        sx={{ cursor: 'pointer' }}
                    />
                ),
            },] : [];

        if (displaySetting('display_gtin_column') && showGtinIcon) {
            editDataColumn.push({
                field: 'gtinIcon',
                headerName: '',
                sortable: false,
                minWidth: 40,
                flex: 2,
                renderCell: (params) => (
                    <CropFreeIcon
                        color={params.row.gtin === null ? 'primary' : 'disabled'}
                        sx={{ cursor: 'pointer' }}
                    />
                ),
                stopClickPropagation: true,
            });
        }

        if (displaySetting('display_id_column') && showExternalId) {
            editDataColumn.push({ field: 'external_id', headerName: 'ID', sortable: false, minWidth: 80, flex: 2 });
        }

        if (extraColumns && extraColumns.length > 0) {
            let extraColumnsConct = extraColumns.map((element, index) => {
                let returnColumns = { ...element, id: index.toString() };
                if (returnColumns.field === 'timestamp') {
                    returnColumns = {
                        ...returnColumns,
                        flex: 1.6,
                        sortable: false,
                        fontSize: 2,
                        align: 'center',
                        autoSizeRowsMode: 'AllCells',
                        WrapMode: true,
                        renderCell: (params) => { return dateFormattedDatagrid(params.value) }
                    };
                }
                return returnColumns;
            });
            return [...editDataColumn, ...extraColumnsConct];
        } else {
            return [];
        }
    };

    const [columns, setColumns] = useState([
        {
            field: 'sendImages',
            headerName: '',
            sortable: false,
            minWidth: 40,
            flex: 2,
            renderCell: (params) => (
                <CameraAltIcon
                    sx={{
                        cursor: 'pointer',
                        color: params.row.needs_image ? theme.palette.primary.main : getColor(params.row.image_issues)
                    }}
                />
            ),
            stopClickPropagation: true,
        },
        ...createColumns(),
    ]);

    useEffect(() => {
        const handleResize = () => {
            const newColumns = [...columns];
            const gtinColumnIndex = newColumns.findIndex((column) => column.field === 'gtin');

            // Check window.innerWidth and conditionally show/hide the 'gtin' column
            if (window.innerWidth < 900) {
                if (gtinColumnIndex !== -1) {
                    newColumns.splice(gtinColumnIndex, 1);
                    setColumns(newColumns);
                }
            } else {
                if (gtinColumnIndex === -1) {
                    newColumns.push({ field: 'gtin', headerName: 'Código de barras', width: 150, sortable: false });
                    setColumns(newColumns);
                }
            }
        };

        // Initial setup
        handleResize();

        // Attach the event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [columns]);

    return (
        <DataGrid
            autoHeight={true}
            localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, footerRowSelected: () => '' }}
            columns={columns}
            rows={products}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            slotProps={{
                pagination: {
                    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
                }
            }}
            initialState={{
                ...products.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
            onCellClick={handleCellClick}
        />
    )
}

export default ProductsDatagrid;
