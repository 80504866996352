import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Sidebar from '../components/Sidebar'
import MenuItem from '@mui/material/MenuItem';
import ModalAlert from '../components/ModalAlert';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ProductEditor from '../components/ProductEditor'
import CenteredLoading from '../components/CenteredLoading';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';
import SearchBar from '../components/SearchBar.js';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';


function SearchPage() {
    const [products, setProducts] = useState(null);
    const [timer, setTimer] = useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const defSidebarTitle = 'Buscar Produtos';
    const [sidebarTitle, setSidebarTitle] = useState(defSidebarTitle);
    const [search, setSearch] = useState('');
    const [doSearch, setDoSearch] = useState('');
    const [filter, setFilter] = useState('');
    const [abortController, setAbortController] = useState(null);
    const { displaySetting } = useAuth();

    const getProductsBySearch = async () => {
        if (!doSearch && (filter === '' || filter === 'id_dalla')) {
            refreshProducts();
            return;
        }

        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        setProducts(null);

        ApiService.post("/products_search", { search: doSearch, filter: filter }, true, newAbortController.signal).then((response) => {
            setProducts(response.products);
            setAlertMessage("");
        }).catch((error) => {
            if (error.message !== "Request was cancelled" || error.name !== "AbortError") {
                return;
            }
            setErrorMsg(error.message);
        });
    }

    const setErrorMsg = (msg) => {
        setProducts([]);
        setAlertMessage(msg);
    }

    const refreshProducts = () => {
        window.document.title = defSidebarTitle + ' | ' + displaySetting('site_name');
        ApiService.get("/all_products").then((response) => {
            setProducts(response.products);
            setAlertMessage("");
        }).catch((error) => {
            setErrorMsg(error.message);
        });
    }

    React.useEffect(() => {
        getProductsBySearch();
    }, [doSearch, filter]);

    React.useEffect(() => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                setDoSearch(search);
            }, 200)
        );
    }, [search]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    }

    const resetSearch = () => {
        setDoSearch('');
        setSearch('');
        refreshProducts();
    }

    return (
        <Sidebar title={sidebarTitle}>
            <Paper variant="outlined" sx={{
                display: 'flex',
                flexDirection: 'column',
                p: { xs: 2, md: 3 }, mb: 2
            }}>
                {sidebarTitle === defSidebarTitle &&
                    <Stack sx={{mb: 4}} spacing={2} direction="row">
                        <Box sx={{ flexGrow: 1 }}>
                            <SearchBar inputValue={search} setInputValue={setSearch} expandable={false} showMobile={true}></SearchBar>
                        </Box>
                        <Box sx={{ flexShrink: 0, minWidth: '150px' }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >Filtro</InputLabel>
                                <Select variant='standard' value={filter} onChange={handleFilterChange} sx={{ height: 25 }}> {/*TODO: It is not aligned */}
                                    <MenuItem value=""><em>Todos</em></MenuItem>
                                    <MenuItem value={'id_dalla'}>ID Interno</MenuItem>
                                    <MenuItem value={'gtin'}>GTIN</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                }
                {(products !== null) ?
                    <ProductEditor products={products} refreshProducts={resetSearch} setSidebarTitle={setSidebarTitle} parentTitle={defSidebarTitle} />
                    : <CenteredLoading />
                }
            </Paper>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar >
    );
}

export default SearchPage;
