import userStore from '../stores/userStore'
import * as Constants from './constants'

export const getApiHeaders = () => {
    const { user } = userStore.getState();
    return { 'jwt-token': user?.token, 'x-client-name': user?.selectedClient.client }
}

const _request = async (path, method, data, auth = true, doNotStringify = false, signal = null) => {
    let url = Constants.API_URL + path;

    let headers = !doNotStringify ? { 'Accept': 'application/json', 'Content-Type': 'application/json' } : {};

    if (auth === true) {
        headers = { ...headers, ...getApiHeaders() };
    }

    const handleResponse = (response) => {
        return response;
    };

    const fetchOptions = {
        method: method,
        headers: headers,
    };

    if (signal) {
        fetchOptions.signal = signal;
    }

    if (method === 'GET') {
        let params = '';
        if (data !== undefined && data !== null) {
            params = '?' + Object.keys(data).map(key => key + '=' + data[key]).join('&');
        }
        url += params;

        return new Promise((resolve, reject) => {
            fetch(url, fetchOptions)
                .then(handleResponse)
                .then(response => resolve(response))
                .catch(error => {
                    if (error.name === 'AbortError') {
                        reject(new Error('Request was cancelled'));
                    } else {
                        reject(error);
                    }
                });
        });
    } else if (method === 'POST') {
        if (data === undefined || data === null)
            data = {};

        fetchOptions.body = doNotStringify ? data : JSON.stringify(data);

        return new Promise((resolve, reject) => {
            fetch(url, fetchOptions)
                .then(handleResponse)
                .then(response => resolve(response))
                .catch(error => {
                    if (error.name === 'AbortError') {
                        reject(new Error('Request was cancelled'));
                    } else {
                        reject(error);
                    }
                });
        });
    } else {
        return new Promise((_, reject) => {
            reject({ status: 405 });
        });
    }
}

const redirectToLogin = () => {
    ApiService.post("/logout", {}).then((_) => {
        localStorage.removeItem(Constants.LOCAL_STORAGE_KEY);
        window.location.href = '/login';
    }).catch((_) => {
        localStorage.removeItem(Constants.LOCAL_STORAGE_KEY);
    });
}

const ApiService = {
    handleRequest: async (path, method, data, auth = true, doNotStringify = false, signal = null) => {
        try {
            let response = await _request(path, method, data, auth, doNotStringify, signal);

            let api_error_status = [400, 404, 500];

            if (!response || response instanceof TypeError || api_error_status.includes(response.status)) {
                throw new Error(Constants.API_ERROR_MESSAGE);
            }

            if (response.status === 401 || response.status === 403) {
                redirectToLogin();
                throw new Error(Constants.API_UNAUTHORIZED_MESSAGE);
            }

            if (response.error) {
                throw new Error(response.message || response.error);
            }

            response = await response.json();

            if (!response.success) {
                throw new Error(response.message || Constants.API_ERROR_MESSAGE);
            }

            return response.data || response.message || true;
        } catch (error) {
            console.log('Error in API Service', error);
            throw error;
        }
    },
    get: async (path, data, auth = true, signal = null) => {
        return await ApiService.handleRequest(path, 'GET', data, auth, false, signal);
    },
    post: async (path, data, auth = true, signal = null) => {
        return await ApiService.handleRequest(path, 'POST', data, auth, false, signal);
    },
    postWithFormData: async (path, data, auth = true, signal = null) => {
        return await ApiService.handleRequest(path, 'POST', data, auth, true, signal);
    },
    put: async (path, data, auth = true, signal = null) => {
        return await ApiService.handleRequest(path, 'PUT', data, auth, false, signal);
    },
    delete: async (path, data, auth = true, signal = null) => {
        return await ApiService.handleRequest(path, 'DELETE', data, auth, false, signal);
    }
}

export default ApiService;
