import React, { useEffect, useState } from 'react';
import {
    ListItem, CircularProgress, Typography, Divider, Grid, Box,
    Accordion, AccordionSummary, AccordionDetails, Button, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import {
    Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator,
    TimelineConnector, TimelineContent, TimelineDot,
    timelineOppositeContentClasses
} from '@mui/lab';
import {
    ExpandMore as ExpandMoreIcon, LocalShipping as ShippingIcon
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Constants from '../utils/constants';
import ApiService from '../utils/ApiService';
import ModalAlert from './ModalAlert';
import ItemsList from './ItemsList';
import { dateFormatted, orderTitleFormatted } from '../utils/utils';

const OrderDisplay = ({ orderId, setSidebarTitle }) => {
    const [alertMessage, setAlertMessage] = React.useState('');
    const [titleItems, settitleItems] = useState('');
    const [order, setOrder] = useState(null);
    const [shippingDialogOpen, setShippingDialogOpen] = useState(false);
    const [loadingShipping, setLoadingShipping] = useState(false);

    const maxLength = 60;
    const { displaySetting, checkPrivilege } = useAuth();

    const fetchOrder = async () => {
        ApiService.get('/order', { "order_id": orderId }).then((response) => {
            setOrder(response);
        }).catch((error) => {
            setAlertMessage(error.message);
        });
    }

    useEffect(() => {
        fetchOrder();
    }, []);

    function getListItems() {
        const result = orderTitleFormatted(order, maxLength, 'listing_title');
        settitleItems(result);
        window.document.title = result + " | " + displaySetting('site_name');
    }

    const handleConfirmShipping = () => {
        setLoadingShipping(true);
        setShippingDialogOpen(false);
        ApiService.post('/generate_delivered_shipping', { "order_id": order.id }).then((_) => {
            setShippingDialogOpen(false);
            setLoadingShipping(false);
            setOrder(null);
            fetchOrder();
        }).catch((error) => {
            setLoadingShipping(false);
            setAlertMessage(error.message);
        });
    }

    const ListFees = ({ billing }) => {
        return billing.fees.map((fee, index) => {
            if (!fee.value)
                return null;
            return (
                <Grid container sx={{ mb: 0.5 }} key={index}>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8} textAlign='left'>
                        <Typography  >
                            {fee.description}
                        </Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4} textAlign='right'>
                        <Typography >
                            - {fee.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                    </Grid>
                </Grid>
            );
        });
    }

    const PaymentDetails = ({ billing }) => {
        return (
            <Box>
                <Typography variant="h5" align='left' sx={{ mb: 2 }}>
                    Pagamento
                </Typography>
                <Box sx={{ ml: 2 }}>
                    <Grid container sx={{ mb: 0.5 }}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8} textAlign='left'>
                            <Typography >
                                Preço da Venda
                            </Typography>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4} textAlign='right'>
                            <Typography >
                                {billing?.sale_price ? billing.sale_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "N/A"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <ListFees billing={billing} />
                    <Divider sx={{ mb: 0.5, borderWidth: 1, backgroundColor: '#000000' }} />
                    <Grid container sx={{ mb: 0.5 }}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8} textAlign='left'>
                            <Typography >
                                Preço Final
                            </Typography>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4} textAlign='right'>
                            <Typography >
                                {billing.net_income.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }

    const hideDocuments = (documents) => {
        return documents.filter(doc => doc.filepath !== null).length > 0;
    }

    const InvoiceDocuments = ({ documents }) => {
        return (
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ mb: 0.5 }}>
                    Documentos
                </Typography>
                <Grid container>
                    {hideDocuments(documents) ?
                        documents.map((doc, index) => (
                            doc.filepath &&
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign='left' key={index}>
                                <ListItem primary={doc.description} >
                                    <a href={Constants.DOCUMENTS_ENDPOINT + doc.filepath} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >
                                        {doc.description}
                                    </a>
                                </ListItem>
                            </Grid>
                        ))
                        : 'Nenhum documento disponível'
                    }
                </Grid>
            </Box>
        );
    };

    const ShippingData = ({ shipping }) => {
        return (
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ mb: 0.5 }}>
                    Dados de Envio
                </Typography>
                <Grid container>
                    {shipping.address_strings.map((address, index) => (
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign='left' key={index}>
                            <ListItem primary={address} dense={true}>
                                {address}
                            </ListItem>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    const ProductsItems = ({ items }) => {
        return (
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Produtos
                </Typography>
                <ItemsList items={items} />
            </Box>
        );
    }

    const ShippingStatusHistory = ({ status_history }) => {
        const [expanded, setExpanded] = React.useState(false);
        status_history.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        const TimelineStatusItem = ({ status, showTimelineConnector = true }) => {
            return (
                <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                        {dateFormatted(new Date(status.timestamp))}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        {showTimelineConnector && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>{status.description}</TimelineContent>
                </TimelineItem>
            );
        }
        return (
            <Timeline sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}>
                <Accordion
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}
                    sx={{ width: '100%' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        {!expanded && <TimelineStatusItem key={0} status={status_history[0]} showTimelineConnector={false} />}
                    </AccordionSummary>
                    <AccordionDetails>
                        {status_history.map((status, index) => (
                            <TimelineStatusItem key={index} status={status} showTimelineConnector={(status_history.length - 1) !== index} />
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Timeline>
        );
    }

    const ShippingDetails = ({ shipping }) => {
        return (
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ mb: 0.5 }}>
                    Detalhes de Envio
                </Typography>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign='left'>
                        <ListItem primary={shipping.service} dense={true}>
                            {shipping.service || "Serviço não informado"}
                        </ListItem>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign='left'>
                        <ListItem primary={shipping.tracking_num} dense={true}>
                            Código de Rastreio: {order.shipping.tracking_num || "Não encontrado"}
                        </ListItem>
                    </Grid>
                </Grid>
                {order.shipping.status_history && order.shipping.status_history.length > 0 &&
                    <ShippingStatusHistory status_history={order.shipping.status_history} />}
            </Box>
        );
    }

    const Buyer = ({ buyer }) => {
        return (
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ mb: 0.5 }}>
                    Comprador
                </Typography>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign='left'>
                        <ListItem primary={buyer.name} dense={true}>
                            {buyer.name}
                        </ListItem>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign='left'>
                        <ListItem primary={buyer.cpf} dense={true}>
                            {buyer.cpf}
                        </ListItem>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const SaleDetails = ({ order }) => {
        return (
            <Grid container textAlign={'center'} sx={{ mb: 2 }}>
                <Grid >
                    <Typography align="center" ml={1.7}>
                        <img src={Constants.ICON_DIR + order.marketplace_id + ".ico"} width="30" />
                    </Typography>
                </Grid>
                <Grid >
                    <Typography align="center" ml={1}>
                        Venda #{order.external_id} - {dateFormatted(new Date(order.placed_at))}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    const OrderTitle = ({ title }) => {
        return (
            <Typography variant="h5" align='left' sx={{ mb: 2, mr: 2 }}>
                {title}
            </Typography>
        );
    }

    useEffect(() => {
        if (order !== null) {
            getListItems();
            setSidebarTitle("Venda #" + order.external_id);
        }
    }, [order]);

    return (
        <React.Fragment>
            {order && order.items && order.items.length > 0 ? (
                <Grid container>
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <OrderTitle title={titleItems} />
                        <SaleDetails order={order} />
                        <Buyer buyer={order.buyer} />
                        {order.shipping && <ShippingDetails shipping={order.shipping} />}
                        <ProductsItems items={order.items} />
                        {order.shipping && <ShippingData shipping={order.shipping} />}
                        <InvoiceDocuments documents={order.documents} />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <PaymentDetails billing={order.billing} />
                    </Grid>
                    <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                </Grid>
            ) : <CircularProgress />}

            {checkPrivilege(Constants.USER_PRIVILEGE.ADMIN) &&
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {order !== null && order.shipping.id === null &&
                        <LoadingButton loading={ loadingShipping } loadingPosition="start" startIcon={<ShippingIcon />}
                            variant="contained" onClick={() => { setShippingDialogOpen(true); }}
                            sx={{ p: 2, bottom: 0, mt: 2}} color="error">
                            Marcar como entregue
                        </LoadingButton>
                    }
                </Box>
            }

            <Dialog open={shippingDialogOpen} onClose={() => { setShippingDialogOpen(false); }}>
                <DialogTitle>Entrega finalizada</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja realmente marcar essa entrega como finalizada?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setShippingDialogOpen(false); }}>
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={ handleConfirmShipping }>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}

export default OrderDisplay;
