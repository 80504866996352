import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Sidebar from '../components/Sidebar';
import ModelAlert from '../components/ModalAlert';
import ProductEditor from '../components/ProductEditor'
import CenteredLoading from '../components/CenteredLoading';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';

export default function ProductsPage() {
    const [alertMessage, setAlertMessage] = React.useState('');
    const [products, setProducts] = useState(null);
    const [sidebarTitle, setSidebarTitle] = useState('Produtos para Editar');
    const { displaySetting } = useAuth();

    const setErrorMsg = (msg) => {
        setProducts([]);
        setAlertMessage(msg);
    }

    const refreshProducts = async () => {
        window.document.title = "Produtos | " + displaySetting('site_name');
        ApiService.get('/products').then((response) => {
            setProducts(response.products);
            setAlertMessage("");
        }).catch((error) => {
            setErrorMsg(error.message);
        });
    }

    useEffect(() => {
        refreshProducts();
    }, []);

    return (
        <Sidebar title={sidebarTitle}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }}>
                {(products !== null) ?
                    <ProductEditor products={products} refreshProducts={refreshProducts} setSidebarTitle={setSidebarTitle} parentTitle={'Produtos para Editar'} />
                    : <CenteredLoading />
                }
            </Paper>
            <ModelAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar >
    );
}
